import React from 'react';
import List from 'antd/es/list';
import Row from 'antd/es/row';
import Tag from 'antd/es/tag';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { translateTestData } from 'common/helpers/test.helper';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { EComplexity, ETestStatus, ITestModel } from 'entities/Test/Test.models';

export enum ESubtitleTypes {
  ShortDescription = 'shortDescription',
  TryCount = 'tryCount'
}

interface IComponentProps {
  item: ITestModel;
  onItemClick: (itemModel: ITestModel) => void;
  subtitle?: ESubtitleTypes;
  hideComplexity?: boolean;
  smallSize?: boolean;
}
type AllProps = IComponentProps;

const getColorByComplexity = (complexity: string) => {
  switch (complexity) {
    case EComplexity.Low: {
      return 'success';
    }
    case EComplexity.Medium: {
      return 'warning';
    }
    case EComplexity.Pro: {
      return 'error';
    }
    default: {
      return undefined;
    }
  }
};

const TestListItemComponent: React.FC<AllProps> = props => {
  const { item, onItemClick, subtitle = ESubtitleTypes.TryCount, hideComplexity, smallSize = false } = props;
  const { t } = useTranslation();
  const { tryCount, firstImage, lastStatus, id, complexity, icon } = item;
  const showAppraisal = lastStatus === ETestStatus.AI || lastStatus === ETestStatus.Moderation || lastStatus === ETestStatus.New;
  const { title, skills } = translateTestData(item);
  const imgWidth = smallSize ? 50 : 80;
  const itemStyle = smallSize ? 'p-2 mb-2' : 'p-4 mb-3';

  return (
    <List.Item
      key={id}
      className={`players__test-list_item cursor-pointer ant-card ${itemStyle}`}
      onClick={() => onItemClick(item)}
    >
      <Row className="flex-noWrap" align="middle">
        <Row align="middle">
          <ImagePrivate
            preview={false}
            width={imgWidth}
            height={imgWidth}
            imgId={icon?.id || firstImage?.id}
            alt="Image-test"
            className="radius-default mr-4"
          />
        </Row>
        <Row justify="center" className="players__test-list_item-body" gutter={[0, 4]} wrap={false}>
          <div className="ant-typography-ellipsis ant-typography-ellipsis-multiple-line fs-xxs font-weight-7" title={title}>
            {title}
          </div>
          {showAppraisal && (
            <Tag color="processing" className="width-fit">
              {t('Appraisal')}
            </Tag>
          )}
          {subtitle === ESubtitleTypes.ShortDescription ? (
            <Typography.Text type="secondary" className="d-block">
              {skills}
            </Typography.Text>
          ) : (
            <Typography.Text type="secondary">
              {t('Try')} {tryCount} {t('Count')}
            </Typography.Text>
          )}
          {!hideComplexity && complexity && (
            <Tag className="complexity-tag width-fit" color={getColorByComplexity(complexity)}>
              {t(complexity)}
            </Tag>
          )}
        </Row>
      </Row>
    </List.Item>
  );
};

export const TestListItem = TestListItemComponent;
