import i18n from 'i18next';
import React from 'react';
import Row from 'antd/es/row';
import { IBasePathParams } from 'common/models/requestModels';
import { ILocationModel } from 'common/helpers/location.helper';
import DefaultAvatar from 'app/assets/images/pp-avatar-empty.svg';
import { getImageUrl } from 'entities/Image/Image.helper';
import { EImageSize } from 'entities/Image/Image.models';

export interface IPlayerCollection extends Components.Schemas.PlayerPureCollectionDto {}
export interface IPlayerAcademyModelParams extends Paths.PlayerControllerGetAcademyWithPlayer.PathParameters {}
export interface IHistoryData extends Components.Schemas.HistoryDataDto {}
export interface IHistoryDataInfo extends Components.Schemas.HistoryInfoDto {}
export interface IPlayerChangeAcademyParams extends Components.Schemas.MovePlayerDto, IBasePathParams {}

export interface IPlayerSearchCollection extends Components.Schemas.PlayerSearchCollectionDto {}
export interface IPlayerSearchCollectionFilter extends Paths.PlayerControllerSearch.QueryParameters {}
export interface IPlayerSearchModel extends Components.Schemas.PlayerSearchDto {}

export interface IPlayerMergeParams extends Components.Schemas.MergePlayersDto {}

export interface IPlayerAddressModel extends Components.Schemas.AddressDto {}
export interface IPlayerInviteParams extends Components.Schemas.InvitePlayerByEmailDto {}
export interface IPlayerAcademyCollectionFilter
  extends Paths.PlayerControllerGetAcademyListWithPlayer.QueryParameters,
    Paths.PlayerControllerGetAcademyListWithPlayer.PathParameters {
  resultRangesValue?: { id: string }[];
  location?: ILocationModel;
  tab?: EPlayerAcademyTabKeys;
}
export interface IPlayerCollectionFilter extends Paths.PlayerControllerList.QueryParameters {
  location?: ILocationModel;
  playerName?: string;
  ageGroup?: Paths.PlayerControllerGetAcademyListWithPlayer.Parameters.AgeGroup;
}
export interface IPlayerModel extends Components.Schemas.PlayerLiteDto {}
export interface IPlayerUpdateParams extends Components.Schemas.UpdatePlayerDto, IBasePathParams {}
export interface IPlayerUpdateByMentorParams extends Components.Schemas.UpdatePlayerByMentorDto, IBasePathParams {
  // todo remove weight height positions after 5062
  weight?: number;
  height?: number;
  positions?: ('GK' | 'LB' | 'CB' | 'RB' | 'CDM' | 'LM' | 'CM' | 'RM' | 'CAM' | 'LW' | 'CF' | 'RW' | 'ST')[];
}
export interface IPlayerParamsModel extends IPlayerUpdateParams, IBasePathParams {}
export interface IPlayerUpdateMentorRequestSettings extends Components.Schemas.UpdatePlayerJuniStatSettingsDto, IBasePathParams {}
export interface IPlayerSkillModel extends Components.Schemas.PlayerSkillDto {}
export interface IPlayerSkillCollection extends Components.Schemas.PlayerSkillCollectionDto {}
export interface IPlayerTestResultCollection extends Components.Schemas.PlayerTestResultCollectionDto {}
export interface IPlayerSkillCollectionFilter {
  id: string;
  skillId: string;
}
export interface IPlayerRatingCollection extends Components.Schemas.RatingCollectionDto {}
export interface IPlayerRatingCollectionFilter {
  id: string;
}
export interface IPlayerTestResultsParams {
  testId: string;
  playerId: string;
  onlyBestOfDay?: boolean;
}

export interface IPlayerVideoModel extends Components.Schemas.PlayerVideoDto {}
export interface IPlayerVideoCollection extends Components.Schemas.PlayerVideoCollectionDto {}
export interface IPlayerVideoCollectionFilter
  extends Paths.PlayerVideoControllerList.PathParameters,
    Paths.PlayerVideoControllerList.QueryParameters {}
export interface IAddPlayerVideoParams extends Components.Schemas.CreatePlayerVideoDto {
  playerId: string;
}
export interface IUpdatePlayerVideoParams extends Components.Schemas.UpdatePlayerVideoDto {
  id: string;
  playerId: string;
}
export interface IDeletePlayerVideoParams {
  id: string;
  playerId: string;
}

export interface IPlayerShieldSponsorModel extends Components.Schemas.SponsorDto {}

export interface IPlayerPublicProfileModel extends Components.Schemas.PlayerPublicProfileDto {}
export interface IPlayerPublicProfileTestResults extends Components.Schemas.PlayerToTestResultsDto {}
export interface IPlayerPublicProfileTestResultsParams extends Paths.PublicPlayerControllerGetTestStatistic.PathParameters {}

export interface IPlayerToTestData extends Components.Schemas.PlayerToTestDataDto {}
export interface IPlayerToTestInfo extends Components.Schemas.PlayerToTestInfoDto {}

export interface IPlayerProfileModel extends Components.Schemas.PlayerProfileDto {}
export interface IPlayerProfileAddressModel extends Components.Schemas.PlayerProfilePlayerAddressDto {}
export interface IPlayerProfileSkillModel extends Components.Schemas.PlayerProfileSkillDto {}
export interface ISkillModel extends Components.Schemas.SkillDto {}
export interface IPlayerProfileSkillLiteModel extends Components.Schemas.PlayerSkillForPlayerLiteDto {}
export interface IPlayerProfileMicroSkillModel extends Components.Schemas.PlayerProfileSkillToMicroSkillDto {}
export interface IPlayerProfileMicroSkillTestsModel extends Components.Schemas.PlayerProfileMicroSkillToTestDto {}
export interface IPlayerProfileVideoModel extends Components.Schemas.PlayerProfilePlayerVideoDto {}

export interface IPlayerProfileCollection {
  meta: { count: number };
  data: IPlayerProfileModel[];
}
export interface IPlayerProfileCollectionParams {
  players: { id: string; keepCache?: boolean }[];
}

export interface IPlayerProfileTestResultsModel extends Components.Schemas.PlayerTestResultsDto {}
export interface IPlayerProfileTestResultsLastModel extends Components.Schemas.PlayerTestResultsLastPassDto {}
export interface IPlayerProfileTestResultsHistoryModel extends Components.Schemas.PlayerTestResultsHistoryDto {}
export interface IPlayerProfileTestResultsParams extends Paths.PlayerControllerGetPlayerTestResults.PathParameters {}
export interface IPlayerProfileParams
  extends Paths.PlayerControllerGetPlayerProfile.PathParameters,
    Partial<Paths.PlayerControllerGetPlayerProfile.QueryParameters> {}

export interface IPlayerCheckTokenParams
  extends Components.Schemas.CheckMagicTokenDto,
    Partial<Paths.PlayerControllerCheckMagicToken.PathParameters> {}

export const MIN_PLAYER_WEIGHT = 5; // kg
export const MAX_PLAYER_WEIGHT = 200; // kg
export const MIN_PLAYER_HEIGHT = 50; // cm
export const MAX_PLAYER_HEIGHT = 220; // cm
export const MAX_PLAYER_FIELD_POSITIONS = 3;

export const PLAYER_COMPARE_LS_FIELD_NAME = 'junistat-player-compare';
export type TPlayerCompareModel = string[] | undefined;

interface IPlayerProfileAcademyModel extends Components.Schemas.PlayerProfileAcademyDto {}

export interface IPlayerProfilePlayerModel
  extends Partial<Omit<IPlayerModel, 'address' | 'image' | 'rating' | 'recommendation'>> {
  address?: Partial<IPlayerProfileAddressModel>;
  image?: { id: string | null };
  rating?: number | null;
  recommendation?: string | null;
  academy?: IPlayerProfileAcademyModel;
}

export enum EPlayerVisibilityMode {
  Public = 'public',
  Protected = 'protected',
  Private = 'private'
}

export enum EPlayerVisibilitySettingsValues {
  Public = 'public',
  Protected = 'protected',
  Private = 'private',
  Hidden = 'hidden'
}

export const EPlayersVisibilityModeFromSettings: { [key in EPlayerVisibilitySettingsValues]: EPlayerVisibilityMode } = {
  [EPlayerVisibilitySettingsValues.Public]: EPlayerVisibilityMode.Public,
  [EPlayerVisibilitySettingsValues.Protected]: EPlayerVisibilityMode.Protected,
  [EPlayerVisibilitySettingsValues.Private]: EPlayerVisibilityMode.Private,
  [EPlayerVisibilitySettingsValues.Hidden]: EPlayerVisibilityMode.Private
};

export enum EPlayerVisibilityModeText {
  public = 'all',
  protected = 'subscribed',
  private = 'nobody',
  hidden = 'hidden'
}

export enum EPlayerSuccessMessage {
  UpdateSuccess = 'Information about player is updated'
}

export const PLAYER_ERROR_CODES = {
  IncorrectPhone: 'validation.incorrectPhoneNumber'
};

export enum EPlayerTabKeys {
  Academy = 'academies',
  Training = 'training',
  Progress = 'progress',
  TestResults = 'test-results',
  Tests = 'tests',
  Video = 'video',
  Setting = 'settings',
  RFS = 'rfs',
  Contacts = 'contacts'
}

export enum EPlayerUnauthorizedTabKeys {
  Training = 'training',
  Progress = 'progress',
  TestResults = 'test-results'
}

export enum EPlayerAcademyTabKeys {
  Search = 'search',
  MyRequests = 'myRequests',
  Follow = 'follow'
}

export enum EPlayerSettingsTabKeys {
  Profile = 'profile',
  Subscription = 'subscription'
}

export enum EPlayerTechSkills {
  Stop = 'stop',
  Control = 'control',
  Maintaining = 'maintaining',
  HitPrecision = 'hitPrecision',
  ImpactForce = 'impactForce',
  ShortPass = 'shortPass',
  LongPass = 'longPass'
}

export enum EPlayerWFS {
  PlayingWithWeakFoot = 'playingWithWeakFoot'
}

export enum EPlayerLeadingLegs {
  Left = 'left',
  Right = 'right'
}

export enum EPlayerPhysicalSkills {
  Acceleration = 'acceleration',
  Coordination = 'coordination',
  Reaction = 'reaction',
  Agility = 'agility',
  Balance = 'balance',
  JumpingAbility = 'jumpingAbility',
  Power = 'power',
  Speed = 'speed'
}

export const PHYSICAL_SKILLS = [
  EPlayerPhysicalSkills.Speed,
  EPlayerPhysicalSkills.Acceleration,
  EPlayerPhysicalSkills.Coordination,
  EPlayerPhysicalSkills.Reaction,
  EPlayerPhysicalSkills.Agility,
  EPlayerPhysicalSkills.Balance,
  EPlayerPhysicalSkills.JumpingAbility,
  EPlayerPhysicalSkills.Power
];

export const TECH_SKILLS = [
  EPlayerTechSkills.Stop,
  EPlayerTechSkills.Control,
  EPlayerTechSkills.Maintaining,
  EPlayerTechSkills.HitPrecision,
  EPlayerTechSkills.ImpactForce,
  EPlayerTechSkills.ShortPass,
  EPlayerTechSkills.LongPass
];

export enum EPlayerAgeGroup {
  U7 = 'U7',
  U8 = 'U8',
  U9 = 'U9',
  U10 = 'U10',
  U11 = 'U11',
  U12 = 'U12',
  U13 = 'U13',
  U14 = 'U14',
  U15 = 'U15',
  U16 = 'U16',
  U17 = 'U17',
  U17p = 'U17+'
}

export const DefaultAgeGroup = EPlayerAgeGroup.U12;

export const PlayerAgeGroup = [
  EPlayerAgeGroup.U7,
  EPlayerAgeGroup.U8,
  EPlayerAgeGroup.U9,
  EPlayerAgeGroup.U10,
  EPlayerAgeGroup.U11,
  EPlayerAgeGroup.U12,
  EPlayerAgeGroup.U13,
  EPlayerAgeGroup.U14,
  EPlayerAgeGroup.U15,
  EPlayerAgeGroup.U16,
  EPlayerAgeGroup.U17,
  EPlayerAgeGroup.U17p
] as EPlayerAgeGroup[];

export enum EFieldPositions {
  Goalkeeper = 'GK', // Голкипер
  LeftBack = 'LB', // Левый защитник
  CenterBack = 'CB', // Центральный защитник
  RightBack = 'RB', // Павый защитник
  DefensiveMidfielder = 'CDM', // Опорный полузащитник
  LeftMidfielder = 'LM', // Левый полузащитник
  CentralMidfielder = 'CM', // Центральный полузащитник
  RightMidfielder = 'RM', // Правый полузащитник
  AttackingMidfielder = 'CAM', // Атакуюущий полузащитник
  LeftWinger = 'LW', // Левый фланговый нападающий
  Forward = 'CF', // Форвард
  RightWinger = 'RW', // Правый фланговый нападающий
  Striker = 'ST' // Центральный нападающий
}

export const FIELD_POSITIONS: { [key in EFieldPositions]: { label: string } } = {
  [EFieldPositions.Goalkeeper]: { label: 'Goalkeeper' },
  [EFieldPositions.LeftBack]: { label: 'Left back' },
  [EFieldPositions.CenterBack]: { label: 'Center back' },
  [EFieldPositions.RightBack]: { label: 'Right back' },
  [EFieldPositions.DefensiveMidfielder]: { label: 'Defensive midfielder' },
  [EFieldPositions.LeftMidfielder]: { label: 'Left midfielder' },
  [EFieldPositions.CentralMidfielder]: { label: 'Central midfielder' },
  [EFieldPositions.RightMidfielder]: { label: 'Right midfielder' },
  [EFieldPositions.AttackingMidfielder]: { label: 'Attacking midfielder' },
  [EFieldPositions.LeftWinger]: { label: 'Left winger' },
  [EFieldPositions.Forward]: { label: 'Forward' },
  [EFieldPositions.RightWinger]: { label: 'Right winger' },
  [EFieldPositions.Striker]: { label: 'Striker' }
};

const ALL_PLAYER_FIELD_POSITIONS = Object.keys(FIELD_POSITIONS) as EFieldPositions[];

export const FIELD_POSITIONS_BY_AGE = {
  [EPlayerAgeGroup.U7]: [
    EFieldPositions.Forward,
    EFieldPositions.RightMidfielder,
    EFieldPositions.CentralMidfielder,
    EFieldPositions.LeftMidfielder,
    EFieldPositions.CenterBack,
    EFieldPositions.Goalkeeper
  ],
  [EPlayerAgeGroup.U8]: [
    EFieldPositions.Forward,
    EFieldPositions.RightMidfielder,
    EFieldPositions.CentralMidfielder,
    EFieldPositions.LeftMidfielder,
    EFieldPositions.CenterBack,
    EFieldPositions.Goalkeeper
  ],
  [EPlayerAgeGroup.U9]: [
    EFieldPositions.Forward,
    EFieldPositions.RightMidfielder,
    EFieldPositions.CentralMidfielder,
    EFieldPositions.LeftMidfielder,
    EFieldPositions.CenterBack,
    EFieldPositions.Goalkeeper
  ],
  [EPlayerAgeGroup.U10]: [
    EFieldPositions.Forward,
    EFieldPositions.RightMidfielder,
    EFieldPositions.CentralMidfielder,
    EFieldPositions.LeftMidfielder,
    EFieldPositions.CenterBack,
    EFieldPositions.Goalkeeper
  ],
  [EPlayerAgeGroup.U11]: [
    EFieldPositions.Forward,
    EFieldPositions.RightMidfielder,
    EFieldPositions.CentralMidfielder,
    EFieldPositions.LeftMidfielder,
    EFieldPositions.LeftBack,
    EFieldPositions.RightBack,
    EFieldPositions.CenterBack,
    EFieldPositions.Goalkeeper
  ],
  [EPlayerAgeGroup.U12]: [
    EFieldPositions.Forward,
    EFieldPositions.RightMidfielder,
    EFieldPositions.CentralMidfielder,
    EFieldPositions.LeftMidfielder,
    EFieldPositions.LeftBack,
    EFieldPositions.RightBack,
    EFieldPositions.CenterBack,
    EFieldPositions.Goalkeeper
  ],
  [EPlayerAgeGroup.U13]: [
    EFieldPositions.Forward,
    EFieldPositions.RightMidfielder,
    EFieldPositions.CentralMidfielder,
    EFieldPositions.LeftMidfielder,
    EFieldPositions.LeftBack,
    EFieldPositions.RightBack,
    EFieldPositions.CenterBack,
    EFieldPositions.Goalkeeper
  ],
  [EPlayerAgeGroup.U14]: ALL_PLAYER_FIELD_POSITIONS,
  [EPlayerAgeGroup.U15]: ALL_PLAYER_FIELD_POSITIONS,
  [EPlayerAgeGroup.U16]: ALL_PLAYER_FIELD_POSITIONS,
  [EPlayerAgeGroup.U17]: ALL_PLAYER_FIELD_POSITIONS,
  [EPlayerAgeGroup.U17p]: ALL_PLAYER_FIELD_POSITIONS
};

export const PLAYER_RATINGS_MAP = [
  { limit: 95, value: 5 },
  { limit: 90, value: 4.5 },
  { limit: 87, value: 4 },
  { limit: 84, value: 3.5 },
  { limit: 80, value: 3 },
  { limit: 75, value: 2.5 },
  { limit: 70, value: 2 },
  { limit: 60, value: 1.5 },
  { limit: 50, value: 1 },
  { limit: 40, value: 0.5 },
  { limit: 39, value: 0 }
];
//  todo check after JS-4569
export interface IResultItemModel {
  units?: string;
  playerValue: number;
  isBest?: boolean;
}

export interface IPlayerItemModel {
  firstName?: string;
  lastName?: string;
  image?: string;
}
const renderItem = (item: string) => {
  return (
    <Row justify="center">
      <div className="test-result-history-table__badge">{item}</div>
    </Row>
  );
};
export const tablePlayerProfileTestResultConfig = (isDesktopValue?: boolean) => {
  return [
    {
      title: i18n.t('Player'),
      dataIndex: 'img',
      key: 'img',
      fixed: true,
      ellipsis: true,
      colSpan: isDesktopValue ? 2 : 1,
      width: 60,
      // eslint-disable-next-line react/no-multi-comp
      render: (image: string) => {
        const imgUrl = getImageUrl(image, EImageSize.X56) || DefaultAvatar;
        return {
          props: { style: { background: '#ffffff' } },
          children: <img className="radius-default" src={imgUrl} width={60} height={60} alt="avatar" />
        };
      }
    },
    {
      title: isDesktopValue ? '' : i18n.t('First name'),
      dataIndex: 'player',
      key: 'player',
      colSpan: isDesktopValue ? 0 : 1,
      width: isDesktopValue === undefined ? 300 : isDesktopValue ? 300 : 150,
      fixed: false,
      ellipsis: true,
      // eslint-disable-next-line react/no-multi-comp
      render: (renderItemValue: IPlayerItemModel) => {
        const { firstName, lastName } = renderItemValue || {};
        const isName = firstName && lastName;
        const firstNameValue = isName ? firstName : i18n.t('Is hidden');
        const lastNameValue = isName ? lastName : i18n.t('Player');

        return {
          props: { style: { background: '#ffffff' } },
          children: (
            <Row align="middle" wrap={false} className="flex-noWrap width-full">
              <Row className="flex-column" justify="space-between" wrap={false}>
                <span className="ellipsis flex-noWrap uppercase test-result-history-table__name">{lastNameValue}</span>
                <span className="ellipsis flex-noWrap uppercase test-result-history-table__name">{firstNameValue}</span>
              </Row>
            </Row>
          )
        };
      }
    },
    {
      title: i18n.t('Place'),
      dataIndex: 'place',
      key: 'place',
      ellipsis: true,
      width: 90,
      render: renderItem
    },
    {
      title: i18n.t('Age group'),
      dataIndex: 'ageGroup',
      key: 'ageGroup',
      ellipsis: true,
      width: 90,
      render: (ageGroup: EPlayerAgeGroup) => renderItem(i18n.t(ageGroup))
    }
  ];
};
