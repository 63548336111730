import i18n from 'i18next';
import { EResultTypes, INTEGER_TYPES } from 'entities/PlayerTest/PlayerTest.models';
import { ITestModel, ITestPureModel } from 'entities/Test/Test.models';

export const getLongIR = (ir: string) => {
  const longIrTag = `${ir}-long`;

  return i18n.exists(longIrTag) ? i18n.t(longIrTag) : '';
};

export const isUnitInteger = (unit: EResultTypes = EResultTypes.Default) => INTEGER_TYPES.includes(unit);

export const translateTestData = (
  item: Partial<ITestModel | ITestPureModel> | null
): { title: string; description: string; shortDescription: string; skills: string } => {
  const { tag, skillsDependencies = [] } = item ?? {};
  const unknownTitle = i18n.t('Unknown title');
  const unknownDescription = i18n.t('No description');

  if (tag) {
    const title = i18n.t(`${tag}.Title`) === `${tag}.Title` ? unknownTitle : i18n.t(`${tag}.Title`);
    const description = i18n.t(`${tag}.Description`) === `${tag}.Description` ? unknownDescription : i18n.t(`${tag}.Description`);
    const shortDescription =
      i18n.t(`${tag}.Short description`) === `${tag}.Short description` ? '' : i18n.t(`${tag}.Short description`);
    const skillArray = skillsDependencies?.map(skillItem => (i18n.exists(`${skillItem}`) ? i18n.t(`${skillItem}`) : ''));
    const skills = skillArray?.length ? skillArray?.join(' ') : '';
    return { title, description, shortDescription, skills };
  } else {
    return { title: unknownTitle, description: unknownDescription, shortDescription: '', skills: '' };
  }
};

export const parseOrderField = (field?: any, tagsArray?: string[]) => {
  if (field && tagsArray) {
    const index = tagsArray?.findIndex(item => item === field) + 1;
    if (!!index && index > 0) {
      return `br_${index}`;
    } else {
      return field;
    }
  } else {
    return undefined;
  }
};
