import React, { ReactText } from 'react';
import { Table } from 'antd';
import { StoreBranch } from '@axmit/redux-communications';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import { IBaseFilterModel } from 'common/models/requestModels';
import { queryToObject } from 'common/helpers/filters.helper';
import { ItemsNotFound } from 'common/components/Text/ItemsNotFound';

interface IComponentProps<
  Model,
  Collection extends { data: Model[]; meta: { count?: number } },
  IConfig extends ColumnsType<any>,
  Filter extends any = undefined
> {
  config: IConfig;
  filter?: Filter;
  disablePagination?: boolean;
  props?: TableProps<any>;
}
export interface ISorter {
  order: 'descend' | 'ascend' | null | undefined;
  field: string | number | readonly ReactText[] | undefined;
}
interface IComponentState {
  pageIndex?: number;
  sorter?: ISorter;
}
const firstPage = 1;

abstract class TableCommonComponent<
  Collection extends { data: Model[]; meta: { count?: number } },
  Model extends object,
  IConfig extends ColumnsType<any>,
  IProps,
  Filter extends any = undefined,
  Params = any
> extends React.PureComponent<IComponentProps<Model, Collection, IConfig, Filter> & IProps, IComponentState> {
  constructor(props: IComponentProps<Model, Collection, IConfig, Filter> & IProps) {
    super(props);
    const queryObject: IComponentState = queryToObject({ pageIndex: 1 });
    this.state = { pageIndex: queryObject.pageIndex, sorter: undefined };
  }
  componentDidMount(): void {
    const { pageIndex } = this.state;
    this.loadData(pageIndex || firstPage, 10);
  }
  componentWillUnmount(): void {
    this.clearCollection();
  }

  componentDidUpdate(prevProps: Readonly<IComponentProps<Model, Collection, IConfig, Filter> & IProps>) {
    if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
      this.loadData(firstPage, 10);
    }
  }
  render() {
    const { config, disablePagination, props } = this.props;
    const { pageIndex } = this.state;
    const collection = this.getCollection();
    const { data: collectionData, loading } = collection;
    const items = collectionData?.data || [];
    const count = collectionData?.meta.count || 0;

    return (
      <Table<Model>
        {...props}
        columns={config}
        dataSource={items}
        rowKey="id"
        size="middle"
        onChange={this.onChange}
        onRow={record => {
          return {
            onClick: () => this.onRowClick(record)
          };
        }}
        title={this.renderHeader}
        rowClassName={this.rowClassName}
        pagination={
          disablePagination
            ? false
            : {
                onChange: this.loadData,
                total: count,
                position: ['bottomCenter'],
                defaultCurrent: pageIndex,
                showSizeChanger: false,
                current: pageIndex
              }
        }
        locale={{ emptyText: loading ? <div /> : <ItemsNotFound /> }}
        loading={loading}
      />
    );
  }

  loadData = (page: number, pageSize?: number) => {
    const { sorter } = this.state;
    const pageNumber = page >= 1 ? page : 1;
    const offset = pageSize ? pageSize * (pageNumber - 1) : 0;
    this.setState({ pageIndex: pageNumber }, () => this.addQuery(pageNumber, sorter));
    this.loadCollection({ limit: pageSize, offset }, sorter);
  };

  onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => {
    if (extra?.action === 'sort' && !Array.isArray(sorter)) {
      const { order, field } = sorter;
      const { current, pageSize } = pagination;
      this.setState({ sorter: { order, field } }, () => {
        this.loadData(current || 1, pageSize);
      });
    }
  };

  clearCollection: () => void = () => undefined;
  renderHeader?: () => JSX.Element;
  onRowClick: (item: Model) => void = () => undefined;
  addQuery: (pageIndex: number, sorter?: ISorter) => void = () => undefined;
  rowClassName: (record: Model, index: number, indent: number) => string = () => '';
  abstract getCollection: () => StoreBranch<Collection, Params>;
  abstract loadCollection: (params: IBaseFilterModel, sorter?: ISorter) => void;
}

export const TableCommon = TableCommonComponent;
