import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Slider from 'antd/es/slider';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { useMediaPredicate } from 'react-media-hook';
import { valueToPercent } from 'common/helpers/number.helper';
import { notExistTranslationWrapper } from 'common/helpers/translate.helper';
import { normalizeTestValue } from 'common/helpers/normalize.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';
import { IPlayerProfileMicroSkillModel } from 'entities/Player/Player.models';

interface IProps {
  result: Partial<IPlayerProfileMicroSkillModel>;
  isWrap?: boolean;
}

export const PlayerTestResultAverage: React.FC<IProps> = props => {
  const { result, isWrap } = props;
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { tag, value, avgValue, maxValue, minValue, ranging, valuePercent, units } = result;
  const normalizedValue = normalizeTestValue(tag, value, units as EResultTypes);
  const bestValue = ranging === 'LB' ? maxValue : minValue;
  const normalizedBestValue = normalizeTestValue(tag, bestValue, units as EResultTypes);
  const resultTitle = notExistTranslationWrapper(tag);
  const valueOut = valuePercent || useMemo(() => valueToPercent(value, Number(minValue), Number(maxValue), ranging), []);
  const avgPercent = useMemo(() => valueToPercent(avgValue, Number(minValue), Number(maxValue), ranging), []);
  const hideAvg = avgValue === maxValue || avgPercent === minValue;
  const hideBar = units === EResultTypes.Bool;

  return isDesktop ? (
    <Row gutter={{ xl: 8 }} key={result.tag} align="middle" justify="space-between" className="width-full mx-0">
      <Col xs={24} md={isWrap || hideBar ? 24 : 11} className="player-test-result-average__results pl-0">
        <Row
          justify="space-between"
          align="middle"
          className="player-test-result-average__results__item"
          gutter={[8, 0]}
          wrap={false}
        >
          <Col className="player-test-result-average__results__item__title">
            <Typography.Text
              className="d-block ellipsis color-black-primary player-test-result-average__results__item__title"
              title={resultTitle}
            >
              {resultTitle}
            </Typography.Text>
          </Col>
          <Col className="player-test-result-average__results__item__value" flex={0}>
            <Typography.Text className="d-block color-black-primary t-align-e font-weight-5 t-nowrap">
              {normalizedValue} {notExistTranslationWrapper(result?.units, { count: normalizedValue - 0 })}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row justify="space-between" align="middle" className="player-test-result-average__results__item" wrap={false}>
          {!hideBar ? (
            <Col className="player-test-result-average__progress width-full" span={18}>
              <Slider
                tooltipVisible={false}
                step={0.01}
                min={0}
                max={100}
                value={valueOut || 0}
                marks={
                  avgPercent === null || hideAvg
                    ? undefined
                    : { [avgPercent || 0]: <Tooltip overlay={avgValue?.toFixed(2) || 0}> {t('Average')} </Tooltip> }
                }
              />
            </Col>
          ) : null}
          <Col className="player-test-result-average__results__item__value t-align-e ml-3">
            <Typography.Text className="d-block color-text-secondary  font-weight-5 t-nowrap" ellipsis>
              {normalizedBestValue} {notExistTranslationWrapper(result?.units, { count: normalizedBestValue - 0 })}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <Row key={result.tag} align="middle" className="width-full mx-0">
      <Col span={24} className="pl-0">
        <Row justify="space-between" align="middle">
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Typography.Text className="d-block ellipsis color-black-primary" title={resultTitle}>
                {resultTitle}
              </Typography.Text>
              <Typography.Text className="font-weight-4 color-text-secondary">{t('Benchmark')}</Typography.Text>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Typography.Text className="d-block color-black-primary font-weight-5 t-nowrap">
                {normalizedValue} {notExistTranslationWrapper(result?.units, { count: normalizedValue - 0 })}
              </Typography.Text>
              <Typography.Text className="d-block color-text-secondary font-weight-5 t-nowrap">
                {normalizedBestValue} {notExistTranslationWrapper(result?.units, { count: normalizedBestValue - 0 })}
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      </Col>
      {!hideBar ? (
        <Col xs={24} sm={24} md={24} lg={24} xl={isWrap ? 24 : 10} className="player-test-result-average__progress mt-3">
          <Slider
            tooltipVisible={false}
            step={0.01}
            min={0}
            max={100}
            value={valueOut || 0}
            marks={
              avgPercent === null || hideAvg
                ? undefined
                : { [avgPercent || 0]: <Tooltip overlay={avgValue?.toFixed(2) || 0}> {t('Average')} </Tooltip> }
            }
          />
        </Col>
      ) : null}
    </Row>
  );
};
