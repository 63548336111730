import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import Typography from 'antd/es/typography';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import { DownloadOutlined } from '@ant-design/icons';
import { getBaseUrl } from 'common/helpers/axios.helper';
import { objectToQuery } from 'common/helpers/filters.helper';
import { getBirthdayAgeGroup, dateToMomentFormat } from 'common/helpers/date.helper';
import { clientDateFormat } from 'common/models/dateModels';
import { AgeGroupSelector } from 'common/components/Selector/AgeGroupSelector';
import { isAcademyFranchisor } from 'common/helpers/franchise.helper';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { TestUploaderTypeSelector } from 'entities/Test/components/Selectors/TestUploaderTypeSelector';
import { EPlayerAgeGroup } from 'entities/Player/Player.models';
import { ETestResultFilter, ETestUploaderType, ITestResultsHistoryCollectionFilter } from 'entities/Test/Test.models';
import { ITeamPureModel } from 'entities/Team/Team.models';
import { TeamSelector } from 'entities/Team/components/TeamSelector';
import { IAcademySearchModel } from 'entities/Academy/Academy.models';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { AcademyModelProviderContext } from 'entities/Academy/components/View/Card/AcademyViewCardProvider';
import { PlayerProfileProviderContext } from 'entities/Player/components/PlayerProvider';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {
  onFilterChange: (filter: Partial<ITestResultsHistoryCollectionFilter>) => void;
  filter?: Partial<ITestResultsHistoryCollectionFilter>;
  disableFilters?: boolean;
  filterConfig?: ETestResultFilter[];
}

const { RangePicker } = DatePicker;
type AllProps = IComponentProps & IAuthConnectedProps;

const TestResultTableFilterCardComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const {
    onFilterChange,
    filter,
    authModel,
    disableFilters,
    filterConfig = [
      ETestResultFilter.PlayerName,
      ETestResultFilter.AgeGroup,
      ETestResultFilter.UploaderType,
      ETestResultFilter.BirthYear,
      ETestResultFilter.TestDateRange,
      ETestResultFilter.Academy,
      ETestResultFilter.Coach,
      ETestResultFilter.Team,
      ETestResultFilter.ShowAll,
      ETestResultFilter.Download
    ]
  } = props;
  const fromPlayerAcademy = useMemo(() => filter?.fromPlayerAcademy, [filter]);
  const isUserAuth = useMemo(() => authModel?.data?.access, [authModel]);
  const token = useMemo(() => authModel?.data?.access?.token, [authModel]);
  const host = getBaseUrl();
  const { name, ageGroups, years, trainerName, team, academy, from, to, uploaderType, showAll } = filter || {};
  const { academyModel } = React.useContext(AcademyModelProviderContext);
  const { profile } = React.useContext(PlayerProfileProviderContext);
  const playerAcademyId = profile?.data?.academy?.academyId;
  const academyModelData = academyModel?.data;
  const isFranchisor = isAcademyFranchisor(academyModelData);
  const yearValue: Moment | undefined = years ? dateToMomentFormat(years.toString(), 'YYYY') : undefined;
  const academyIdFilter = academy?.id;

  const debounceSearch = useDebouncedCallback((text: string) => {
    const nameValue = text === '' ? undefined : text;
    onFilterChange({ name: nameValue });
  }, 300);

  const debounceTrainerSearch = useDebouncedCallback((text: string) => {
    const nameValue = text === '' ? undefined : text;
    onFilterChange({ trainerName: nameValue });
  }, 300);

  const onChangeRangePicker = (value: [Moment | null, Moment | null] | null) => {
    const fromRaw = value?.[0];
    const toRaw = value?.[1];

    onFilterChange({
      from: fromRaw ? getUTCStartOfDay(fromRaw) : undefined,
      to: toRaw ? getUTCEndOfDay(toRaw) : undefined
    });
  };

  const onChangeAgeSelector = (ageGroup: EPlayerAgeGroup) => {
    onFilterChange({ ageGroups: ageGroup ? [ageGroup] : undefined });
  };
  const onChangeUploaderTypeSelector = (value: ETestUploaderType) => {
    onFilterChange({ uploaderType: value });
  };
  const onChangeBirthdayAgeGroupSelector = (date: any) => {
    const year = parseInt(getBirthdayAgeGroup(date)) || undefined;
    onFilterChange({ years: year ? [year] : undefined });
  };

  const onChangeTeamSelector = (rawTeam?: ITeamPureModel | ITeamPureModel[]) => {
    const selectedTeam: ITeamPureModel | undefined = Array.isArray(rawTeam) ? rawTeam?.[0] : rawTeam;
    const filterContent = selectedTeam ? { id: selectedTeam?.id, name: selectedTeam?.name } : undefined;
    onFilterChange({ team: filterContent });
  };

  const onChangeAcademySelector = (academyModelValue?: IAcademySearchModel) => {
    const academyFilter = academyModelValue
      ? {
          id: academyModelValue?.id,
          name: academyModelValue?.name
        }
      : undefined;

    onFilterChange({ academy: academyFilter });
  };

  const onChangeShowAllCheckbox = useCallback(
    (e: CheckboxChangeEvent) => {
      onFilterChange({ showAll: !e.target.checked, onlyMy: e.target.checked });
    },
    [onFilterChange]
  );
  const onChangeAcademyCheckbox = useCallback(
    (e: CheckboxChangeEvent) => {
      onFilterChange({ fromPlayerAcademy: e.target.checked });
    },
    [onFilterChange]
  );

  const rangeValue = useMemo<[Moment | undefined, Moment | undefined]>(() => {
    const fromValue = from ? moment(from) : undefined;
    const toValue = to ? moment(to).utc() : undefined;

    return [fromValue, toValue];
  }, [from, to]);

  const renderFilters = useCallback(
    () =>
      filterConfig?.map(item => {
        switch (item) {
          case ETestResultFilter.PlayerName: {
            return (
              <Col key={item} xs={24} sm={disableFilters ? 6 : 3}>
                <Typography.Text>{t('Player name')}</Typography.Text>
                <Input
                  className="mt-3"
                  size="large"
                  name="playerName"
                  type="text"
                  placeholder={t('Player name')}
                  defaultValue={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => debounceSearch.callback(e.target.value)}
                />
              </Col>
            );
          }
          case ETestResultFilter.AgeGroup: {
            return (
              <Col key={item} xs={12} sm={disableFilters ? 3 : 2}>
                <Typography.Text>{t('Group')}</Typography.Text>
                <AgeGroupSelector
                  allowClear
                  value={ageGroups}
                  className="width-full mt-3"
                  size="large"
                  onChange={onChangeAgeSelector}
                />
              </Col>
            );
          }
          case ETestResultFilter.UploaderType: {
            return (
              <Col key={item} xs={12} sm={disableFilters ? 3 : 2}>
                <Typography.Text ellipsis title={t('Testing')}>
                  {t('Testing')}
                </Typography.Text>
                <TestUploaderTypeSelector
                  allowClear
                  value={uploaderType}
                  className="width-full mt-3"
                  size="large"
                  onChange={onChangeUploaderTypeSelector}
                />
              </Col>
            );
          }
          case ETestResultFilter.BirthYear: {
            return (
              <Col key={item} xs={24} sm={disableFilters ? 3 : 2}>
                <Typography.Text ellipsis title={t('Year of birth')}>
                  {t('Year of birth')}
                </Typography.Text>
                <DatePicker
                  allowClear
                  value={yearValue as any}
                  size="large"
                  defaultPickerValue={moment().subtract(15, 'years')}
                  className="width-full mt-3"
                  format="YYYY"
                  placeholder={t('Year')}
                  onChange={onChangeBirthdayAgeGroupSelector}
                  picker="year"
                />
              </Col>
            );
          }
          case ETestResultFilter.TestDateRange: {
            return (
              !disableFilters && (
                <Col key={item} xs={24} sm={5}>
                  <Typography.Text>{t('Test date')}</Typography.Text>
                  <RangePicker
                    // @ts-ignore
                    value={rangeValue}
                    className="width-full mt-3"
                    size="large"
                    allowEmpty={[true, true]}
                    showNow={false}
                    showTime={false}
                    placeholder={[t('Date from'), t('Date to')]}
                    format={clientDateFormat}
                    onChange={onChangeRangePicker}
                  />
                </Col>
              )
            );
          }
          case ETestResultFilter.Academy: {
            return (
              !disableFilters && (
                <Col key={item} xs={24} sm={3}>
                  <Typography.Text>{t('Academy')}</Typography.Text>
                  <AcademySelector
                    className="mt-3"
                    onChange={onChangeAcademySelector}
                    value={academy}
                    disabled={!isFranchisor}
                    size="large"
                    placeholder={t('Academy')}
                  />
                </Col>
              )
            );
          }
          case ETestResultFilter.MyAcademy: {
            return !!playerAcademyId && !!isUserAuth ? (
              <Col key={item} xs={24} sm={4}>
                <Row align="middle" className="height-full">
                  <Checkbox checked={fromPlayerAcademy} onChange={onChangeAcademyCheckbox} className="mt-3">
                    {t('Player academy')}
                  </Checkbox>
                </Row>
              </Col>
            ) : null;
          }
          case ETestResultFilter.Coach: {
            return (
              <Col key={item} xs={24} sm={3}>
                <Typography.Text>{t('Trainer')}</Typography.Text>
                <Input
                  className="mt-3"
                  size="large"
                  name="trainerName"
                  type="text"
                  placeholder={t('Full name')}
                  defaultValue={trainerName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => debounceTrainerSearch.callback(e.target.value)}
                />
              </Col>
            );
          }
          case ETestResultFilter.Team: {
            return (
              !disableFilters && (
                <Col key={item} xs={24} sm={3}>
                  <Typography.Text>{t('Team')}</Typography.Text>
                  <TeamSelector
                    className="mt-3"
                    onChange={onChangeTeamSelector}
                    value={team}
                    academyId={academyIdFilter}
                    size="large"
                    placeholder={t('Team')}
                  />
                </Col>
              )
            );
          }
          case ETestResultFilter.ShowAll: {
            return (
              !disableFilters && (
                <Col key={item} xs={24} sm={3}>
                  <Row align="middle" className="height-full">
                    <Checkbox checked={!showAll} onChange={onChangeShowAllCheckbox}>
                      {t('My academy')}
                    </Checkbox>
                  </Row>
                </Col>
              )
            );
          }
          case ETestResultFilter.Download: {
            // eslint-disable-next-line
            const { tags, academy, team, trainer, teamId, id, fromPlayerAcademy, playerAcademyId, ...filterParamsData } =
              filter || {};
            if (id) {
              const filterParams: ITestResultsHistoryCollectionFilter = { ...filterParamsData, id };

              if (fromPlayerAcademy && playerAcademyId) {
                filterParams.academies = [playerAcademyId];
              }
              if (academy?.id) {
                filterParams.academies = [academy?.id];
              }
              if (team?.id) {
                filterParams.teams = [team?.id];
              }
              if (teamId) {
                filterParams.teams = [teamId];
              }
              if (trainer?.id) {
                filterParams.trainers = [trainer?.id];
              }

              return token ? (
                <Col key={item} xs={24} sm={3}>
                  <Row align="middle" className="height-full">
                    <a
                      href={`${host}/tests/${id}/results-history/csv${objectToQuery({ ...filterParams, token })}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-block"
                    >
                      <Button block type="default" title={t('Download')}>
                        <DownloadOutlined /> {t(`Download`)}
                      </Button>
                    </a>
                  </Row>
                </Col>
              ) : null;
            }
            return null;
          }

          default: {
            return null;
          }
        }
      }),
    [filterConfig, filter]
  );
  return (
    <Row gutter={[16, 16]} className="p-0 mt-6 mb-6">
      {renderFilters()}
    </Row>
  );
};

export const TestResultTableFilterCard = communicationAuth.injector(TestResultTableFilterCardComponent);
