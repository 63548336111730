import React, { useEffect, useState } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Tabs from 'antd/es/tabs';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import Typography from 'antd/es/typography';
import HeartOutlined from '@ant-design/icons/HeartOutlined';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { Tab } from 'common/components/Tab/Tab';
import { ERequestType } from 'common/models/commonModels';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ERoutesMentor, ERoutesAcademy } from 'common/models/routesModel';
import { isAcademyFranchisee } from 'common/helpers/franchise.helper';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { ReactComponent as ThunderboltIcon } from 'app/assets/icons/thunderbolt.svg';
import { PlayersList } from 'entities/Player/components/List/PlayersList';
import { EAcademyTabKeys } from 'entities/Academy/Academy.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EPlayerAgeGroup, IPlayerCollectionFilter, IPlayerModel } from 'entities/Player/Player.models';
import { PlayerListFilterCard } from 'entities/Player/components/Filter/PlayerListFilterCard';
import { DEFAULT_PRIVATE_PLAYER_LIST_GRID } from 'entities/PrivatePlayer/components/List/PrivatePlayersList';
import { EPrivatePlayerActionTypes } from 'entities/PrivatePlayer/PrivatePlayer.models';

type AllProps = IAuthConnectedProps;

const PLAYERS_ACTION_TYPES = [EPrivatePlayerActionTypes.Share, EPrivatePlayerActionTypes.Favorite];

const selectTab = (key?: string): EAcademyTabKeys => {
  switch (key) {
    case EAcademyTabKeys.Search:
      return EAcademyTabKeys.Search;

    case EAcademyTabKeys.Incoming:
      return EAcademyTabKeys.Incoming;

    case EAcademyTabKeys.Favorites:
      return EAcademyTabKeys.Favorites;

    default:
      return EAcademyTabKeys.Search;
  }
};

const onItemClick = (item: IPlayerModel) => {
  window.open(`${ERoutesMentor.Player}/${item.id}`);
};

const AcademySelectionTabComponent: React.FC<AllProps> = props => {
  const { authAcademy } = props;
  const { data: authAcademyModelData } = authAcademy;
  const ageGroupArray = authAcademyModelData?.ageGroups;
  const ageGroup = ageGroupArray && (ageGroupArray[0] as EPlayerAgeGroup);
  const [renderIndex, setRenderIndex] = useState(Math.random());

  const initState = {
    tab: EAcademyTabKeys.Search,
    location: undefined,
    name: undefined,
    visibleOnly: undefined,
    ageGroup: undefined,
    year: undefined,
    skills: [],
    orderField: undefined,
    orderDirection: undefined,
    resultRangesValue: undefined,
    gender: undefined
  };

  const { t } = useTranslation();
  const history = useHistory();
  const [count, setCount] = useState<number | undefined>(0);
  const [state, setState] = useState<Partial<IPlayerCollectionFilter> & { tab?: EAcademyTabKeys }>({
    ...initState,
    ...queryToObject<Partial<IPlayerCollectionFilter>>(initState)
  });

  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);

  useEffect(() => {
    if (authAcademyModelData?.id && isAcademyFranchisee(authAcademyModelData)) {
      history.push(`${ERoutesAcademy.AcademyView}/${authAcademyModelData.id}`);
    }
  }, [authAcademyModelData]);

  const onTabChange = (tab: string) => {
    setState({ ...state, tab: selectTab(tab) });
  };

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  const { tab, ...filter } = state;
  const isIncomingTab = tab === EAcademyTabKeys.Incoming;
  const isFavoritesTab = tab === EAcademyTabKeys.Favorites;
  const requestType = isIncomingTab ? ERequestType.PlayerToAcademy : isFavoritesTab ? ERequestType.AcademyToPlayer : undefined;

  return (
    <>
      <Row className={`pb-5 ${!isDesktop ? 'px-3' : ''}`} justify="space-between" align="middle">
        <Typography.Title className="mb-0" level={isDesktop ? 2 : 3}>
          {t('Selection')}
        </Typography.Title>
      </Row>

      <Tabs activeKey={tab} onChange={onTabChange}>
        <Tabs.TabPane key={EAcademyTabKeys.Search} tab={<Tab title={t('Search')} icon={<SearchIcon />} />} />
        <Tabs.TabPane
          key={EAcademyTabKeys.Incoming}
          tab={<Tab title={t('Inbox')} icon={<ThunderboltIcon />} eventsCount={count || undefined} />}
        />
        <Tabs.TabPane key={EAcademyTabKeys.Favorites} tab={<Tab title={t('Favorites')} icon={<HeartOutlined />} />} />
      </Tabs>

      <Col xs={24}>
        <Row className="height-full mb-5" key={renderIndex}>
          <PlayerListFilterCard onFilterChange={partialFilter => setState({ ...state, ...partialFilter })} filter={state} />
        </Row>
        <Row className={`height-full ${isDesktop ? '' : 'px-5'}`}>
          <PlayersList
            grid={DEFAULT_PRIVATE_PLAYER_LIST_GRID}
            elementId="basicLayout"
            onItemClick={onItemClick}
            filter={{ ...filter, requestType }}
            setCount={setCount}
            defaultAgeGroup={ageGroup}
            actionType={PLAYERS_ACTION_TYPES}
            clearFilter={(tabNew: EAcademyTabKeys) => {
              setState({ ...initState, tab: tabNew });
              setRenderIndex(Math.random());
            }}
          />
        </Row>
      </Col>
    </>
  );
};

export const AcademySelectionTab = communicationAuth.injector(AcademySelectionTabComponent);
