import EditOutlined from '@ant-design/icons/EditOutlined';
import React, { useCallback, useMemo } from 'react';
import { Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { isCurrentUserAcademyForPlayer, isCurrentUserMentorForPlayer } from 'common/helpers/player.helper';
import { ERoutesAcademy, ERoutesMentor } from 'common/models/routesModel';
import { TextBlock } from 'common/components/TextBlock/TextBlock';
import { EAcademyViewTabKeys } from 'entities/Academy/Academy.models';
import { EPlayerTabKeys, FIELD_POSITIONS, IPlayerProfileModel } from 'entities/Player/Player.models';
import { PrivateContent } from 'entities/Auth/components/PrivateContent';
import { EPrivatePlayerTabKeys } from 'entities/PrivatePlayer/PrivatePlayer.models';

interface IProps {
  player: IPlayerProfileModel;
}

const PlayerProfileAboutComponent: React.FC<IProps> = ({ player }) => {
  const { t } = useTranslation();
  const { academyId } = useParams();
  const { push } = useHistory();
  const isMentor = isCurrentUserMentorForPlayer();
  const isPlayerAcademyWorker = isCurrentUserAcademyForPlayer();

  const { weight, height, playerId, about, positions = ['CM', 'CAM', 'CDM'] } = player || {};

  const tooltipPositions = useMemo(() => {
    if (positions?.length) {
      return positions.map(position => t(FIELD_POSITIONS[position].label)).join(',');
    }

    return undefined;
  }, [positions]);

  const isEditAvailableCallback = useCallback(() => isMentor || isPlayerAcademyWorker, [isMentor, isPlayerAcademyWorker]);

  const onEditPlayerAbout = useCallback(() => {
    const path = isMentor
      ? `${ERoutesMentor.Player}/${playerId}/${EPlayerTabKeys.Video}`
      : `${ERoutesAcademy.AcademyView}/${academyId}/${EAcademyViewTabKeys.Players}/${playerId}/${EPrivatePlayerTabKeys.Info}`;

    push(path);
  }, [isMentor, isPlayerAcademyWorker, push]);

  return (
    <Row>
      <TextBlock
        label={t('About player')}
        action={
          <PrivateContent availableFor={isEditAvailableCallback}>
            <EditOutlined className="color-gray cursor-pointer fs-sm hide-on-print" onClick={onEditPlayerAbout} />
          </PrivateContent>
        }
      >
        <Row align="middle" className="width-full mb-5" justify="space-between" wrap={false}>
          <Typography.Text>{t('Height, cm')}</Typography.Text>
          <Typography.Title className="my-0 font-weight-5" level={4}>
            {height || '-'}
          </Typography.Title>
        </Row>
        <Row align="middle" className="width-full mb-5" justify="space-between" wrap={false}>
          <Typography.Text>{t('Weight, kg')}</Typography.Text>
          <Typography.Title className="my-0 font-weight-5" level={4}>
            {weight || '-'}
          </Typography.Title>
        </Row>
        <Row align="middle" className="width-full mb-5" justify="space-between" wrap={false}>
          <Typography.Text>{t('Positions')}</Typography.Text>
          <Tooltip title={tooltipPositions}>
            <Typography.Title className="ml-2 my-0 font-weight-5 t-align-e" level={4} ellipsis>
              {positions?.length ? positions.join(',') : '-'}
            </Typography.Title>
          </Tooltip>
        </Row>
        {about?.length
          ? about
          : isMentor && (
              <span className="hide-on-print">
                {t('Fill information about player profile to get on the radar of scouts and academies')}
              </span>
            )}
      </TextBlock>
    </Row>
  );
};

export const PlayerProfileAbout = PlayerProfileAboutComponent;
