import React, { useMemo } from 'react';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Bar } from 'common/components/Bar/Bar';
import { valueToPercent } from 'common/helpers/number.helper';
import { IPlayerProfileMicroSkillModel } from 'entities/Player/Player.models';

interface IProps {
  data: Partial<IPlayerProfileMicroSkillModel>;
  color?: string;
  bgColor?: string;
  className?: string;
  showValue?: boolean;
  wrapperClassName?: string;
}

const Component: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { data, color, bgColor, className, showValue, wrapperClassName } = props;
  const { value, units, maxValue, minValue, ranging, valuePercent } = data;

  const barValue = useMemo(() => {
    const valuePercentData = valueToPercent(value, Number(minValue), Number(maxValue), ranging);
    return valuePercent !== null && valuePercent !== undefined ? valuePercent * 100 : valuePercentData;
  }, [data]);

  return (
    <div className={classNames('width-full', { [wrapperClassName as string]: Boolean(wrapperClassName) })}>
      {showValue && (
        <Typography.Title level={5} className="mb-5">{`${value ?? '-'}${units ? ` ${t(units)}` : ''}`}</Typography.Title>
      )}
      <Bar className={className} value={barValue} color={color} bgColor={bgColor} />
    </div>
  );
};

export const PlayerTestMicroSkillBar = Component;
