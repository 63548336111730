import React, { FC, useMemo } from 'react';
import { Row, Col } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { normalizeUnitResult } from 'common/helpers/normalize.helper';
import { notExistTranslationWrapper } from 'common/helpers/translate.helper';
import { valueToPercent } from 'common/helpers/number.helper';
import { Bar } from 'common/components/Bar/Bar';
import { IPlayerProfileMicroSkillModel } from 'entities/Player/Player.models';

interface IComponentProps {
  className?: string;
  data: Partial<IPlayerProfileMicroSkillModel>;
  color?: string;
  bgColor?: string;
}

type AllProps = IComponentProps;

export const PlayerTestResultChart: FC<AllProps> = props => {
  const { className, data, color, bgColor } = props;
  const { t } = useTranslation();
  const { tag, value, units, maxValue, minValue, valuePercent, ranging } = data;
  const valuePercentData = useMemo(() => valueToPercent(value, Number(minValue), Number(maxValue), ranging), []);
  const title = tag ? t(tag) : tag;
  const percent = valuePercent !== null && valuePercent !== undefined ? valuePercent * 100 : valuePercentData;

  return (
    <Row className={`width-full height-full ${classnames({ [className as string]: Boolean(className) })}`}>
      <Col xs={24}>
        <Row className="width-full" justify="space-between" wrap={false}>
          <Col className="ellipsis color-black-primary mr-3" title={title}>
            {title}
          </Col>
          <Col className="t-nowrap font-weight-5 color-dark">
            {normalizeUnitResult(value, units)} {notExistTranslationWrapper(units)}
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Bar value={percent} color={color} bgColor={bgColor} />
      </Col>
    </Row>
  );
};
