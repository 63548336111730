import { RuleObject } from 'antd/es/form';
import i18n from 'common/helpers/i18n';
import { decodeGiftcardNumber } from 'common/helpers/giftcard.helper';

export const requiredField = () => ({ required: true, message: i18n.t('Field is required') });

export const commonStringRules = () => [
  {
    min: 2,
    message: i18n.t('Minimum value', { value: 2 })
  },
  {
    max: 64,
    message: i18n.t('Maximum value', { value: 64 })
  },
  {
    pattern: /.*[^\s]$/,
    message: i18n.t('Invalid field value')
  }
];

export const passwordRules = () => ({
  validator(rule: RuleObject, value: string) {
    if (value) {
      if (/[^a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}]/.test(value)) {
        return Promise.reject(i18n.t('Only latin characters is allowed'));
      }
      if (value?.length < 8) {
        return Promise.reject(i18n.t('Password needs 8 or more characters'));
      }
      if (value?.length > 64) {
        return Promise.reject(i18n.t('Password needs 64 or less characters'));
      }
      if (!/[a-z]/.test(value)) {
        return Promise.reject(i18n.t('Password needs at least one lowercase'));
      }
      if (!/[A-Z]/.test(value)) {
        return Promise.reject(i18n.t('Password needs at least one uppercase'));
      }
      if (!/[0-9]/.test(value)) {
        return Promise.reject(i18n.t('Password needs at least one number'));
      }
    }
    return Promise.resolve();
  }
});
export const externalIdRules = () => ({
  validator(rule: RuleObject, value: string) {
    if (!/^[a-zA-Z0-9-_]{2,128}$/.test(value)) {
      return Promise.reject(i18n.t('Invalid field value'));
    }
    return Promise.resolve();
  }
});

export const phoneRule = () => ({
  validator(rule: RuleObject, value: string) {
    if (!value || /^\d+$/g.test(value?.replace('+', ''))) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('Phone mask'));
  }
});

export const maxLengthRule = (maxLength: number) => ({
  max: maxLength,
  message: i18n.t('Maximum characters', { count: maxLength })
});

export const maxNumberRule = (maxNumber: number) => ({
  validator(rule: RuleObject, value: number) {
    if (!value || value <= maxNumber) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('Maximum value', { value: maxNumber }));
  }
});

export const minNumberRule = (minNumber: number) => ({
  validator(rule: RuleObject, value: number) {
    if (!value || value >= minNumber) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('Minimum value', { value: minNumber }));
  }
});

export const maxArrayLengthRule = (maxLength: number) => ({
  validator(rule: RuleObject, value: string) {
    if (!value || value?.length <= maxLength) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('Maximum number of values', { value: maxLength }));
  }
});

export const validateGiftcard = () => ({
  validator(rule: RuleObject, value: string) {
    const giftcard = value?.replace(/ /g, '');
    const { isGiftcardOk } = decodeGiftcardNumber(giftcard);
    if (isGiftcardOk) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('Wrong giftcard'));
  }
});
