import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useParams } from 'react-router';
import Switch from 'antd/es/switch';
import { ITrainerAcademyRequestCollectionFilter } from 'entities/Trainer/Trainer.models';
import { TeamSelector } from 'entities/Team/components/TeamSelector';
import { ITeamPureModel } from 'entities/Team/Team.models';
import { IAcademySearchModel } from 'entities/Academy/Academy.models';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';

export enum ETrainerFilterCardsFilters {
  Search = 'search',
  Team = 'team',
  Academy = 'academy'
}

interface IComponentProps {
  onFilterChange: (filter: Partial<ITrainerAcademyRequestCollectionFilter>) => void;
  filter?: Partial<ITrainerAcademyRequestCollectionFilter>;
  enabledFilters?: ETrainerFilterCardsFilters[];
  isFranshisor?: boolean;
}

const DefaultEnabledFilters = [
  ETrainerFilterCardsFilters.Search,
  ETrainerFilterCardsFilters.Academy,
  ETrainerFilterCardsFilters.Team
];

export const TrainerAcademyListFilterCard: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { onFilterChange, filter, enabledFilters = DefaultEnabledFilters, isFranshisor } = props;
  const { academyId } = useParams();
  const searchValue = filter?.search;
  const academyFilterValue = filter?.academyFilter;
  const teamValue = filter?.teamModel;
  const withoutTeam = filter?.hasTeam !== undefined ? !filter?.hasTeam : undefined;

  const autocompleteSearch = useDebouncedCallback((text: string) => {
    const search = text === '' ? undefined : text;
    onFilterChange({ search });
  }, 300);

  const onChangeTeamSelector = (rawTeam?: ITeamPureModel | ITeamPureModel[]) => {
    const selectedTeam: ITeamPureModel | undefined = Array.isArray(rawTeam) ? rawTeam?.[0] : rawTeam;
    const filterContent = selectedTeam ? { id: selectedTeam?.id, name: selectedTeam?.name } : undefined;
    onFilterChange({ teamModel: filterContent });
  };

  const onChangeAcademySelector = (academyModel?: IAcademySearchModel) => {
    const academyFilter = academyModel
      ? {
          id: academyModel?.id,
          name: academyModel?.name
        }
      : undefined;

    onFilterChange({ academyFilter });
  };

  const onChangeHasTeam = (checked: boolean) => {
    onFilterChange({ hasTeam: checked ? !checked : undefined });
  };

  return (
    <Card className="px-3 mt-5 width-full" bordered={false}>
      <Row gutter={[16, 16]}>
        {enabledFilters.includes(ETrainerFilterCardsFilters.Search) && (
          <Col xs={24} sm={isFranshisor && enabledFilters.includes(ETrainerFilterCardsFilters.Academy) ? 12 : 24}>
            <Typography.Text>{t('Name or email')}</Typography.Text>
            <Input
              className="mt-3"
              size="large"
              name="name"
              type="text"
              placeholder={t('Name or email')}
              defaultValue={searchValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => autocompleteSearch.callback(e.target.value)}
            />
          </Col>
        )}
        {isFranshisor && enabledFilters.includes(ETrainerFilterCardsFilters.Academy) && (
          <Col xs={24} sm={enabledFilters.includes(ETrainerFilterCardsFilters.Search) ? 12 : 24}>
            <Typography.Text>{t('Academy')}</Typography.Text>
            <AcademySelector
              className="mt-3"
              onChange={onChangeAcademySelector}
              value={academyFilterValue}
              size="large"
              placeholder={t('Academy')}
            />
          </Col>
        )}
        {enabledFilters.includes(ETrainerFilterCardsFilters.Team) && (
          <Col xs={24}>
            <Typography.Text>{t('Team')}</Typography.Text>
            <TeamSelector
              className="mt-3"
              onChange={onChangeTeamSelector}
              value={teamValue}
              academyId={isFranshisor ? academyFilterValue?.id : academyId}
              size="large"
              placeholder={t('Team')}
            />
          </Col>
        )}
        {enabledFilters.includes(ETrainerFilterCardsFilters.Team) && (
          <Col xs={24}>
            <Row justify="end" gutter={[12, 8]}>
              <Col className="t-align-e">
                <Typography.Text className="mr-3">{t('Without team')}</Typography.Text>
                <Switch checked={withoutTeam} onChange={onChangeHasTeam} />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  );
};
