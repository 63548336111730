import React from 'react';
import Col from 'antd/es/col';
import List from 'antd/es/list';
import Row from 'antd/es/row';
import { useHistory, useParams } from 'react-router';
import Typography from 'antd/es/typography';
import { useMediaPredicate } from 'react-media-hook';
import { SCREEN_MD } from 'common/const/config.const';
import { objectToQuery } from 'common/helpers/filters.helper';
import { clientDateFullMonthFormat, serverDateFormat } from 'common/models/dateModels';
import { dateFormatter, formatDateFromServer } from 'common/helpers/date.helper';
import { translateTestData } from 'common/helpers/test.helper';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { ITestTeamHistoryModel } from 'entities/Test/Test.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

interface IComponentProps {
  item: ITestTeamHistoryModel;
}
type AllProps = IComponentProps & IUIConnectedProps;

const imgWidth = 70;
const imgHeight = 70;

const TestTeamHistoryListItemComponent: React.FC<AllProps> = props => {
  const { item, openUITestResultModal } = props;
  const history = useHistory();
  const { teamId } = useParams();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);
  const { tag, id, date, baseResultsForView, iconId } = item;
  const { title, skills } = translateTestData({ tag });
  const params = {
    id,
    testTag: tag,
    teamId,
    tags: baseResultsForView,
    from: formatDateFromServer(date, 'YYYY-MM-DD'),
    to: formatDateFromServer(date, 'YYYY-MM-DD'),
    onlyMy: false
  };

  return (
    <List.Item
      key={Math.random()}
      className="cursor-pointer"
      onClick={() => {
        history.replace({ search: objectToQuery(params) });
        openUITestResultModal({
          params
        });
      }}
    >
      <Row className="flex-noWrap" align="top">
        <Col>
          <ImagePrivate
            preview={false}
            width={imgWidth}
            height={imgHeight}
            imgId={iconId}
            alt="Image-test"
            className="radius-default mr-5 mb-2"
          />
        </Col>

        <Row className={`${isDesktop ? 'flex-row' : 'flex-column'} width-full `} justify="space-between" wrap={false}>
          <Row className="flex-column" justify="center" style={{ flexGrow: 1 }}>
            <div className="ant-typography-ellipsis ant-typography-ellipsis-multiple-line fs-xxs font-weight-7" title={title}>
              {title}
            </div>
            <Typography.Text type="secondary" className="mt-2 d-block">
              {skills}
            </Typography.Text>
          </Row>
          <Row justify={isDesktop ? 'end' : 'start'} style={{ flexGrow: 2 }} className="t-nowrap">
            <Typography.Text type="secondary" className="mt-2 d-block">
              {dateFormatter(formatDateFromServer(date, serverDateFormat), clientDateFullMonthFormat)}
            </Typography.Text>
          </Row>
        </Row>
      </Row>
    </List.Item>
  );
};

export const TestTeamHistoryListItem = communicationUI.injector(TestTeamHistoryListItemComponent);
