import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import { useTranslation } from 'react-i18next';
import { useFormMapper } from '@axmit/antd4-helpers';
import moment, { Moment } from 'moment';
import Checkbox from 'antd/es/checkbox';
import { AvatarEditor } from 'common/components/AvatarEditor/AvatarEditor';
import {
  maxArrayLengthRule,
  maxLengthRule,
  maxNumberRule,
  minNumberRule,
  requiredField,
  externalIdRules
} from 'common/helpers/filed-rules';
import { clientDateFormat, serverDateFormat } from 'common/models/dateModels';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType } from 'common/helpers/location.helper';
import { GenderSelect } from 'common/components/GenderSelect';
import { isAcademyFranchisor, useAcademiesFilterWithFranchisor } from 'common/helpers/franchise.helper';
import { InputNumber } from 'common/components/Input/InputNumber';
import { getUTCStartOfDayFromString } from 'common/helpers/date.helper';
import { FieldPositionSelector } from 'common/components/Selector/FieldPositionSelector';
import { birthdayToAgeGroup } from 'common/helpers/player.helper';
import { communicationPrivatePlayer, IPrivatePlayerConnectedProps } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import {
  EPlayerAgeGroup,
  EPlayerVisibilityMode,
  FIELD_POSITIONS_BY_AGE,
  MAX_PLAYER_FIELD_POSITIONS,
  MAX_PLAYER_HEIGHT,
  MAX_PLAYER_WEIGHT,
  MIN_PLAYER_HEIGHT,
  MIN_PLAYER_WEIGHT
} from 'entities/Player/Player.models';
import { LeadingLegSelect } from 'entities/PrivatePlayer/components/LeadingLegSelect';
import { TrainerSelector } from 'entities/Trainer/components/Selectors/TrainerSelector';
import { ELeadingLeg } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { EUserGender } from 'entities/User/User.models';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { TeamSelector } from 'entities/Team/components/TeamSelector';
import { ITeamModel } from 'entities/Team/Team.models';
import { ShareButton } from 'entities/Share/components/SharePlayerButton';
import { EMentorRequestStatus } from 'entities/MentorRequest/MentorRequest.models';

interface IProps {
  academyId?: string;
}

type AllProps = IProps & IAcademyConnectedProps & IPrivatePlayerConnectedProps & IUIConnectedProps & IAuthConnectedProps;

const PrivatePlayerFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const [avatarId, setAvatarId] = useState<string | null>(null);
  const [dateBirth, setDateBirth] = useState<string | Moment>();
  const {
    privatePlayerModel,
    addPrivatePlayerModel,
    updatePrivatePlayerModel,
    academyId,
    academyModel,
    saveUIAcademyLastTeamModel,
    UIAcademyLastTeamModel,
    authAcademy,
    privatePlayerAcademy,
    changePrivatePlayerAcademy,
    openUITransferPlayerModal
  } = props;
  const { data: player, loading, errors, params } = privatePlayerModel;
  const lastSelectedTeam = UIAcademyLastTeamModel.data;
  const academyAddress = academyModel?.data?.address;
  const hidePlayersByDefault = academyModel?.data?.hidePlayersByDefault;
  const disabled = useMemo(() => loading || privatePlayerAcademy?.loading || player?.archived, [
    player,
    loading,
    privatePlayerAcademy
  ]);
  const isHasMentor = player?.mentorRequest?.status === EMentorRequestStatus.Accepted && !player?.mentorRequest?.isVirtual;
  const isEditing = useMemo(() => player?.id, [player]);
  const academiesWithFranchisor = useAcademiesFilterWithFranchisor(academyId, authAcademy);
  const isAuthAcademyFranchisor = isAcademyFranchisor(authAcademy?.data);
  const isAvailableRfsTests = authAcademy?.data?.isAvailableRfsTests;
  const webhookUrl = authAcademy?.data?.franchiseRequest?.franchise?.webhookUrl;
  const showExternalId = isAuthAcademyFranchisor && webhookUrl && isAvailableRfsTests;

  const data = useMemo(() => {
    const defaultTeam =
      lastSelectedTeam?.academyId === academyId && lastSelectedTeam?.lastTeam ? [lastSelectedTeam?.lastTeam] : undefined;

    if (player) {
      const { birthday, trainerLinks, teamLinks, juniCoachPublicity, ...rest } = player;

      return {
        ...rest,
        birthday: birthday && moment(birthday, serverDateFormat),
        trainers: trainerLinks?.map(link => link.trainer),
        teams: teamLinks?.map(link => link.team),
        isHidden: !juniCoachPublicity
      };
    }

    return {
      birthday: undefined,
      address: undefined,
      leadingLeg: undefined,
      gender: undefined,
      teams: defaultTeam,
      isHidden: hidePlayersByDefault
    };
  }, [player, lastSelectedTeam, academyId]);
  const birthday = data?.birthday || moment().subtract(1, 'year');
  const formData: any = useMemo(() => {
    setDateBirth(birthday);

    return {
      ...data,
      birthday,
      address: isEditing ? data?.address : academyAddress,
      leadingLeg: isEditing ? data?.leadingLeg : ELeadingLeg?.Right,
      gender: isEditing ? data?.gender : EUserGender?.Male
    };
  }, [data]);

  const { fields } = useFormMapper(
    [
      'firstName',
      'lastName',
      'about',
      'birthday',
      'address',
      'leadingLeg',
      'gender',
      'trainers',
      'isHidden',
      'recommendation',
      'teams',
      'email',
      'externalId',
      'positions',
      'weight',
      'height'
    ],
    formData,
    params,
    errors
  );

  useEffect(() => {
    if (player?.image) {
      setAvatarId(player.image.id);
    }
  }, [player]);

  const playerAgeGroup: EPlayerAgeGroup | undefined = useMemo(() => {
    return dateBirth ? birthdayToAgeGroup(dateBirth) : undefined;
  }, [dateBirth]);

  const onValuesChangedCallback = useCallback((_, values) => {
    setDateBirth(getUTCStartOfDayFromString(values.birthday));
  }, []);

  const onFinishEditing = (values: any) => {
    if (!disabled) {
      const { isHidden, externalId, email, birthday: birthdayValue } = values;

      const body = {
        ...values,
        image: avatarId,
        juniCoachPublicity: !isHidden,
        juniCoachVisibility: values.isHidden ? EPlayerVisibilityMode.Private : EPlayerVisibilityMode.Public,
        email: email || undefined,
        externalId: externalId || undefined
      };

      if (academyId) {
        body.academy = academyId;
      }
      if (birthdayValue) {
        body.birthday = getUTCStartOfDayFromString(birthdayValue);
      }

      if (body.teams?.length) {
        const teams = [...body.teams] as ITeamModel[];
        const lastTeam = teams.pop();

        if (lastTeam && academyId) {
          saveUIAcademyLastTeamModel({ lastTeam, academyId });
        }
      } else {
        // body.teams = undefined;
      }

      if (isEditing) {
        if (player?.id) {
          updatePrivatePlayerModel({ ...body, id: player.id });
        }
      } else {
        addPrivatePlayerModel(body);
      }
    }
  };

  const onChangeStatus = () => {
    if (player?.id) {
      updatePrivatePlayerModel({ archived: !player?.archived, id: player.id });
    }
  };
  const removeFromAcademy = useCallback(() => {
    if (player?.id) {
      changePrivatePlayerAcademy({ id: player.id, academy: null });
    }
  }, [changePrivatePlayerAcademy, player]);

  const openTransferModal = () => {
    if (player) {
      openUITransferPlayerModal({ playerId: player.id });
    }
  };

  const availableFieldPositions = useMemo(() => {
    if (playerAgeGroup) {
      return FIELD_POSITIONS_BY_AGE[playerAgeGroup];
    }

    return undefined;
  }, [playerAgeGroup]);

  return (
    <Row className="width-full">
      <Form
        onValuesChange={onValuesChangedCallback}
        onFinish={onFinishEditing}
        fields={fields}
        name="create-private-player-form"
        layout="vertical"
        className="private-player-form width-max-full p-5 width-full"
      >
        <Row className="flex-noWrap mb-5">
          <Col className="mr-5">
            <AvatarEditor onChange={setAvatarId} avatarId={avatarId} />
          </Col>
          <Col flex={1}>
            <Row className="width-full">
              <Form.Item rules={[requiredField]} name="firstName" className="mb-3 width-full">
                <Input disabled={disabled} size="large" placeholder={t('First name')} />
              </Form.Item>
            </Row>
            <Row className="width-full">
              <Form.Item rules={[requiredField]} name="lastName" className="mb-0 width-full">
                <Input disabled={disabled} size="large" placeholder={t('Last name')} />
              </Form.Item>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item rules={[requiredField]} name="address" label={t('Address')} className="mb-5">
              <LocationInput types={[ELocationType.Cities]} size="large" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item rules={[requiredField]} name="birthday" label={t('Birthday')} className="width-full mb-5">
              <DatePicker
                disabled={disabled}
                disabledDate={current =>
                  !current ||
                  current.isAfter(
                    moment()
                      .subtract(1, 'year')
                      .toISOString()
                  )
                }
                className="width-full"
                size="large"
                format={clientDateFormat}
                placeholder={t('DD MM YYYY')}
              />
            </Form.Item>
          </Col>
        </Row>
        {!isEditing && (
          <Form.Item name="email" label={`${t('Mentor email')}${t('Optional')}`}>
            <Input size="large" />
          </Form.Item>
        )}

        {showExternalId && (
          <Form.Item rules={[externalIdRules]} name="externalId" label={`${t('External Id')}${t('Optional')}`}>
            <Input size="large" />
          </Form.Item>
        )}
        {!loading && (
          <>
            <Form.Item name="trainers" label={t('Trainers(optional)')} className="mb-7">
              <TrainerSelector mode="multiple" disabled={disabled} size="large" academyId={academiesWithFranchisor} />
            </Form.Item>
            <Form.Item name="teams" label={t('Teams(optional)')} className="mb-7">
              <TeamSelector mode="tags" disabled={disabled} size="large" academyId={academiesWithFranchisor} />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="recommendation"
          label={`${t('Recommendations for parents')} ${t('Optional')}`}
          className="mb-5 width-full"
          rules={[maxLengthRule(1024)]}
        >
          <Input.TextArea
            placeholder={t('This information will be available to parents')}
            autoSize={{ minRows: 2, maxRows: 6 }}
            disabled={disabled}
            size="large"
          />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item rules={[requiredField]} name="leadingLeg" label={t('Leading leg')} className="mb-5">
              <LeadingLegSelect disabled={disabled} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item rules={[requiredField]} name="gender" label={t('Gender')} className="mb-7">
              <GenderSelect disabled={disabled} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={`${t('Height, cm')} ${t('Optional')}`}
              name="height"
              rules={[
                maxNumberRule(MAX_PLAYER_HEIGHT),
                minNumberRule(MIN_PLAYER_HEIGHT),
                formData?.height ? requiredField : { required: false }
              ]}
            >
              <InputNumber precision={1} size="large" className="width-full" placeholder={t('Player height')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={`${t('Weight, kg')} ${t('Optional')}`}
              name="weight"
              rules={[
                maxNumberRule(MAX_PLAYER_WEIGHT),
                minNumberRule(MIN_PLAYER_WEIGHT),
                formData?.weight ? requiredField : { required: false }
              ]}
            >
              <InputNumber precision={1} size="large" className="width-full" placeholder={t('Player weight')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label={`${t('Positions')} ${t('Optional')}`}
              name="positions"
              rules={[maxArrayLengthRule(MAX_PLAYER_FIELD_POSITIONS)]}
            >
              <FieldPositionSelector size="large" mode="multiple" availablePositions={availableFieldPositions} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item valuePropName="checked" name="isHidden" label={t('Player is hidden')}>
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        {isEditing && (
          <Form.Item name="about" label={`${t('About player')} ${t('Optional')}`} rules={[maxLengthRule(1024)]} className="mb-7">
            <Input.TextArea
              disabled={loading}
              autoSize={{ minRows: 2 }}
              placeholder={t('This text will be displayed on the players public page')}
            />
          </Form.Item>
        )}
        {isEditing ? (
          <Row gutter={[8, 8]} className="width-full" justify="space-between">
            <Col>
              <Popconfirm
                okButtonProps={{
                  type: 'text',
                  size: 'middle',
                  danger: true
                }}
                cancelButtonProps={{
                  size: 'middle'
                }}
                okText={t(player?.archived ? 'Activate' : 'Archive')}
                cancelText={t('Cancel')}
                title={t(
                  player?.archived
                    ? 'Are you sure you want to activate the player?'
                    : 'Are you sure you want to archive the player?'
                )}
                onConfirm={onChangeStatus}
              >
                <Button danger={!player?.archived} type="primary" size="large" disabled={loading} loading={loading}>
                  {t(player?.archived ? 'Activate' : 'Archive')}
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              <Popconfirm
                okButtonProps={{
                  type: 'text',
                  size: 'middle',
                  danger: true
                }}
                cancelButtonProps={{
                  size: 'middle'
                }}
                okText={t('Remove')}
                cancelText={t('Cancel')}
                title={t('Are you sure you want to remove the player from the academy')}
                onConfirm={removeFromAcademy}
              >
                <Button danger size="large" disabled={loading} loading={loading}>
                  {t('Remove from academy')}
                </Button>
              </Popconfirm>
            </Col>

            {isAuthAcademyFranchisor && (
              <Col>
                <Button size="large" disabled={player?.archived} onClick={openTransferModal} title={t('Transfer')}>
                  {t('Transfer')}
                </Button>
              </Col>
            )}
            <Col>
              <ShareButton
                playerId={player?.id}
                playerAcademyId={player?.academyLink?.academy?.id}
                disabled={disabled}
                hasMentor={isHasMentor}
                publicity={player?.juniCoachPublicity}
              />
            </Col>
            <Col>
              <Button htmlType="submit" type="primary" size="large" disabled={disabled} loading={loading}>
                {t('Save')}
              </Button>
            </Col>
          </Row>
        ) : (
          <Button block htmlType="submit" type="primary" size="large" disabled={loading} loading={loading}>
            {t('Create')}
          </Button>
        )}
      </Form>
    </Row>
  );
};

export const PrivatePlayerForm = communicationAuth.injector(
  communicationUI.injector(communicationAcademy.injector(communicationPrivatePlayer.injector(PrivatePlayerFormComponent)))
);
