import Typography from 'antd/es/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  height?: number;
}

export const PlayerHeightBadge = ({ height }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <Typography.Title level={5} title={t('Height, cm')} className="px-3 py-1 mb-0 player-height-badge radius-round t-align-c">
      {height ? t('cm', { value: height }) : '-'}
    </Typography.Title>
  );
};
