import Typography from 'antd/es/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  weight?: number;
}

export const PlayerWeightBadge = ({ weight }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <Typography.Title level={5} title={t('Weight, kg')} className="px-3 py-1 mb-0 player-weight-badge radius-round t-align-c">
      {weight ? t('kg', { value: weight }) : '-'}
    </Typography.Title>
  );
};
