import React, { FC } from 'react';
import { Row, Col, Space, Typography, List, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { normalizeStringDate } from 'common/helpers/normalize.helper';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { translateTestData } from 'common/helpers/test.helper';
import { ReactComponent as QuestionCircle } from 'app/assets/icons/QuestionCircle.svg';
import { IPlayerProfileTestResultsLastModel } from 'entities/Player/Player.models';
import { TTestTag } from 'entities/Test/Test.models';
import { PlayerTestResultAverage } from 'entities/PlayerTest/components/PlayerTestResultAverage';

interface IComponentProps {
  data?: IPlayerProfileTestResultsLastModel;
  testTag?: TTestTag;
  previewId?: string | null;
}

type AllProps = IComponentProps;
const IMAGE_SIZE = 48;

export const PlayerSkillsDetail: FC<AllProps> = ({ data, previewId, testTag }) => {
  const { results, date } = data || {};
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { title } = translateTestData({ tag: testTag });

  if (!data) {
    return null;
  }

  return (
    <div className="width-full player-skills-detail">
      <Row wrap={false} justify="space-between" align="middle" className="mb-4">
        <Col>
          <Space wrap={false} size={16}>
            <ImagePrivate
              preview={false}
              height={IMAGE_SIZE}
              width={IMAGE_SIZE}
              imgId={previewId}
              alt="Image-test"
              className="mr-0 radius-default"
            />
            <Typography.Title className="m-0 word-break" level={4}>
              {title || testTag}
            </Typography.Title>
          </Space>
        </Col>
        {date && (
          <Col className="t-align-e">
            <Typography.Text className="fs pp-font-small d-block">{t('Test date')}</Typography.Text>
            <Typography.Text className="fs pp-font-small d-block font-weight-5">{normalizeStringDate(date)}</Typography.Text>
          </Col>
        )}
      </Row>
      {isDesktop && (
        <Row align="middle" justify="space-between" className="width-full px-3 py-2 player-skills-detail__header">
          <Col span={11}>
            <Row justify="space-between" align="middle" wrap={false}>
              <Col>
                <Typography.Text className="color-black-primary font-weight-4">{t('Parameter')}</Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="color-black-primary font-weight-4">{t('Result')}</Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col span={13}>
            <Row justify="end" className="width-full" align="middle">
              <Typography.Text className="color-black-primary font-weight-4 mr-2">{t('Benchmark')}</Typography.Text>
              <Tooltip placement="top" title={t('Best 5% in rating')}>
                <QuestionCircle />
              </Tooltip>
            </Row>
          </Col>
        </Row>
      )}
      <List
        bordered={!isDesktop}
        size="small"
        className="player-skills-detail__list"
        dataSource={results}
        renderItem={item => (
          <List.Item className="width-full  py-4 px-3" key={item.tag}>
            <PlayerTestResultAverage result={item} />
          </List.Item>
        )}
      />
    </div>
  );
};
